/* tslint:disable */
/* eslint-disable */
/**
 * CGN Onboarding Portal
 * Carta Giovani Nazionale Onboarding Portal
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface Address
 */
export interface Address {
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    fullAddress: string;
    /**
     * 
     * @type {Coordinates}
     * @memberof Address
     */
    coordinates?: Coordinates;
}
/**
 * 
 * @export
 * @interface Agreement
 */
export interface Agreement {
    /**
     * 
     * @type {string}
     * @memberof Agreement
     */
    id: string;
    /**
     * 
     * @type {AgreementState}
     * @memberof Agreement
     */
    state: AgreementState;
    /**
     * 
     * @type {string}
     * @memberof Agreement
     */
    imageUrl?: string;
    /**
     * 
     * @type {Array<CompletedStep>}
     * @memberof Agreement
     */
    completedSteps: Array<CompletedStep>;
    /**
     * 
     * @type {EntityType}
     * @memberof Agreement
     */
    entityType?: EntityType;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum AgreementState {
    DraftAgreement = 'DraftAgreement',
    PendingAgreement = 'PendingAgreement',
    ApprovedAgreement = 'ApprovedAgreement',
    RejectedAgreement = 'RejectedAgreement'
}

/**
 * 
 * @export
 * @interface ApiTokens
 */
export interface ApiTokens {
    /**
     * 
     * @type {string}
     * @memberof ApiTokens
     */
    primaryToken: string;
    /**
     * 
     * @type {string}
     * @memberof ApiTokens
     */
    secondaryToken: string;
}
/**
 * 
 * @export
 * @interface ApprovedAgreement
 */
export interface ApprovedAgreement extends Agreement {
    /**
     * 
     * @type {string}
     * @memberof ApprovedAgreement
     */
    startDate: string;
    /**
     * 
     * @type {string}
     * @memberof ApprovedAgreement
     */
    endDate: string;
    /**
     * 
     * @type {string}
     * @memberof ApprovedAgreement
     */
    firstDiscountPublishingDate?: string;
}
/**
 * 
 * @export
 * @interface ApprovedAgreementAllOf
 */
export interface ApprovedAgreementAllOf {
    /**
     * 
     * @type {string}
     * @memberof ApprovedAgreementAllOf
     */
    startDate: string;
    /**
     * 
     * @type {string}
     * @memberof ApprovedAgreementAllOf
     */
    endDate: string;
    /**
     * 
     * @type {string}
     * @memberof ApprovedAgreementAllOf
     */
    firstDiscountPublishingDate?: string;
}
/**
 * 
 * @export
 * @interface BothChannels
 */
export interface BothChannels extends SalesChannel {
    /**
     * 
     * @type {string}
     * @memberof BothChannels
     */
    websiteUrl: string;
    /**
     * 
     * @type {Array<Address>}
     * @memberof BothChannels
     */
    addresses: Array<Address>;
    /**
     * 
     * @type {boolean}
     * @memberof BothChannels
     */
    allNationalAddresses?: boolean;
    /**
     * 
     * @type {DiscountCodeType}
     * @memberof BothChannels
     */
    discountCodeType?: DiscountCodeType;
}
/**
 * 
 * @export
 * @interface BothChannelsAllOf
 */
export interface BothChannelsAllOf {
    /**
     * 
     * @type {string}
     * @memberof BothChannelsAllOf
     */
    websiteUrl: string;
    /**
     * 
     * @type {Array<Address>}
     * @memberof BothChannelsAllOf
     */
    addresses: Array<Address>;
    /**
     * 
     * @type {boolean}
     * @memberof BothChannelsAllOf
     */
    allNationalAddresses?: boolean;
    /**
     * 
     * @type {DiscountCodeType}
     * @memberof BothChannelsAllOf
     */
    discountCodeType?: DiscountCodeType;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum BucketCodeLoadStatus {
    Pending = 'pending',
    Running = 'running',
    Finished = 'finished',
    Failed = 'failed'
}

/**
 * 
 * @export
 * @interface BucketLoad
 */
export interface BucketLoad {
    /**
     * 
     * @type {string}
     * @memberof BucketLoad
     */
    uid: string;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum CompletedStep {
    Profile = 'Profile',
    Discount = 'Discount',
    Document = 'Document'
}

/**
 * 
 * @export
 * @interface Coordinates
 */
export interface Coordinates {
    /**
     * 
     * @type {number}
     * @memberof Coordinates
     */
    latitude: number;
    /**
     * 
     * @type {number}
     * @memberof Coordinates
     */
    longitude: number;
}
/**
 * 
 * @export
 * @interface CreateDiscount
 */
export interface CreateDiscount {
    /**
     * 
     * @type {string}
     * @memberof CreateDiscount
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof CreateDiscount
     */
    name_en: string;
    /**
     * 
     * @type {string}
     * @memberof CreateDiscount
     */
    name_de: string;
    /**
     * 
     * @type {string}
     * @memberof CreateDiscount
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateDiscount
     */
    description_en?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateDiscount
     */
    description_de?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateDiscount
     */
    startDate: string;
    /**
     * 
     * @type {string}
     * @memberof CreateDiscount
     */
    endDate: string;
    /**
     * 
     * @type {number}
     * @memberof CreateDiscount
     */
    discount?: number;
    /**
     * 
     * @type {Array<ProductCategory>}
     * @memberof CreateDiscount
     */
    productCategories: Array<ProductCategory>;
    /**
     * 
     * @type {string}
     * @memberof CreateDiscount
     */
    condition?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateDiscount
     */
    condition_en?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateDiscount
     */
    condition_de?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateDiscount
     */
    staticCode?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateDiscount
     */
    visibleOnEyca?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateDiscount
     */
    eycaLandingPageUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateDiscount
     */
    landingPageUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateDiscount
     */
    landingPageReferrer?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateDiscount
     */
    lastBucketCodeLoadUid?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateDiscount
     */
    lastBucketCodeLoadFileName?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateDiscount
     */
    discountUrl?: string;
}
/**
 * 
 * @export
 * @interface CreateProfile
 */
export interface CreateProfile {
    /**
     * 
     * @type {string}
     * @memberof CreateProfile
     */
    fullName: string;
    /**
     * 
     * @type {string}
     * @memberof CreateProfile
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateProfile
     */
    name_en?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateProfile
     */
    name_de?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateProfile
     */
    taxCodeOrVat: string;
    /**
     * 
     * @type {string}
     * @memberof CreateProfile
     */
    pecAddress: string;
    /**
     * 
     * @type {string}
     * @memberof CreateProfile
     */
    legalOffice: string;
    /**
     * 
     * @type {string}
     * @memberof CreateProfile
     */
    telephoneNumber: string;
    /**
     * 
     * @type {string}
     * @memberof CreateProfile
     */
    legalRepresentativeFullName: string;
    /**
     * 
     * @type {string}
     * @memberof CreateProfile
     */
    legalRepresentativeTaxCode: string;
    /**
     * 
     * @type {CreateReferent}
     * @memberof CreateProfile
     */
    referent: CreateReferent;
    /**
     * 
     * @type {string}
     * @memberof CreateProfile
     */
    description: string;
    /**
     * 
     * @type {string}
     * @memberof CreateProfile
     */
    description_en: string;
    /**
     * 
     * @type {string}
     * @memberof CreateProfile
     */
    description_de: string;
    /**
     * 
     * @type {SalesChannel}
     * @memberof CreateProfile
     */
    salesChannel: SalesChannel;
    /**
     * 
     * @type {Array<CreateReferent>}
     * @memberof CreateProfile
     */
    secondaryReferents?: Array<CreateReferent>;
}
/**
 * 
 * @export
 * @interface CreateReferent
 */
export interface CreateReferent {
    /**
     * 
     * @type {string}
     * @memberof CreateReferent
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof CreateReferent
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof CreateReferent
     */
    role: string;
    /**
     * 
     * @type {string}
     * @memberof CreateReferent
     */
    emailAddress: string;
    /**
     * 
     * @type {string}
     * @memberof CreateReferent
     */
    telephoneNumber: string;
}
/**
 * 
 * @export
 * @interface Discount
 */
export interface Discount {
    /**
     * 
     * @type {string}
     * @memberof Discount
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof Discount
     */
    agreementId: string;
    /**
     * 
     * @type {DiscountState}
     * @memberof Discount
     */
    state: DiscountState;
    /**
     * 
     * @type {string}
     * @memberof Discount
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof Discount
     */
    name_en: string;
    /**
     * 
     * @type {string}
     * @memberof Discount
     */
    name_de: string;
    /**
     * 
     * @type {string}
     * @memberof Discount
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof Discount
     */
    description_en?: string;
    /**
     * 
     * @type {string}
     * @memberof Discount
     */
    description_de?: string;
    /**
     * 
     * @type {string}
     * @memberof Discount
     */
    startDate: string;
    /**
     * 
     * @type {string}
     * @memberof Discount
     */
    endDate: string;
    /**
     * 
     * @type {number}
     * @memberof Discount
     */
    discount?: number;
    /**
     * 
     * @type {Array<ProductCategory>}
     * @memberof Discount
     */
    productCategories: Array<ProductCategory>;
    /**
     * 
     * @type {string}
     * @memberof Discount
     */
    condition?: string;
    /**
     * 
     * @type {string}
     * @memberof Discount
     */
    condition_en?: string;
    /**
     * 
     * @type {string}
     * @memberof Discount
     */
    condition_de?: string;
    /**
     * 
     * @type {string}
     * @memberof Discount
     */
    staticCode?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Discount
     */
    visibleOnEyca?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Discount
     */
    creationDate?: string;
    /**
     * 
     * @type {string}
     * @memberof Discount
     */
    suspendedReasonMessage?: string;
    /**
     * 
     * @type {string}
     * @memberof Discount
     */
    eycaLandingPageUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof Discount
     */
    landingPageUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof Discount
     */
    landingPageReferrer?: string;
    /**
     * 
     * @type {string}
     * @memberof Discount
     */
    lastBucketCodeLoadUid?: string;
    /**
     * 
     * @type {string}
     * @memberof Discount
     */
    lastBucketCodeLoadFileName?: string;
    /**
     * 
     * @type {BucketCodeLoadStatus}
     * @memberof Discount
     */
    lastBucketCodeLoadStatus?: BucketCodeLoadStatus;
    /**
     * 
     * @type {string}
     * @memberof Discount
     */
    discountUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof Discount
     */
    testFailureReason?: string;
}
/**
 * 
 * @export
 * @interface DiscountBucketCodeLoadingProgess
 */
export interface DiscountBucketCodeLoadingProgess {
    /**
     * 
     * @type {number}
     * @memberof DiscountBucketCodeLoadingProgess
     */
    loaded: number;
    /**
     * 
     * @type {number}
     * @memberof DiscountBucketCodeLoadingProgess
     */
    percent: number;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum DiscountCodeType {
    Static = 'Static',
    Api = 'API',
    LandingPage = 'LandingPage',
    Bucket = 'Bucket'
}

/**
 * 
 * @export
 * @enum {string}
 */
export enum DiscountState {
    Draft = 'draft',
    Published = 'published',
    Suspended = 'suspended',
    Expired = 'expired',
    TestPending = 'test_pending',
    TestPassed = 'test_passed',
    TestFailed = 'test_failed'
}

/**
 * 
 * @export
 * @interface Discounts
 */
export interface Discounts {
    /**
     * 
     * @type {Array<Discount>}
     * @memberof Discounts
     */
    items: Array<Discount>;
}
/**
 * 
 * @export
 * @interface Document
 */
export interface Document {
    /**
     * 
     * @type {string}
     * @memberof Document
     */
    documentType: string;
    /**
     * 
     * @type {string}
     * @memberof Document
     */
    documentUrl: string;
    /**
     * 
     * @type {string}
     * @memberof Document
     */
    documentTimestamp: string;
}
/**
 * 
 * @export
 * @interface Documents
 */
export interface Documents {
    /**
     * 
     * @type {Array<Document>}
     * @memberof Documents
     */
    items: Array<Document>;
}
/**
 * 
 * @export
 * @interface DraftAgreement
 */
export interface DraftAgreement extends Agreement {
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum EntityType {
    Private = 'Private',
    PublicAdministration = 'PublicAdministration'
}

/**
 * A payload used for error responses.
 * @export
 * @interface ErrorCode
 */
export interface ErrorCode {
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum ErrorCodeEnum {
    CannotHaveEmptyStaticCodeForProfileWithStaticCode = 'CANNOT_HAVE_EMPTY_STATIC_CODE_FOR_PROFILE_WITH_STATIC_CODE',
    CannotHaveEmptyLandingPageUrlForProfileLandingPage = 'CANNOT_HAVE_EMPTY_LANDING_PAGE_URL_FOR_PROFILE_LANDING_PAGE',
    CannotProceedWithDiscountWithBucketLoadInProgress = 'CANNOT_PROCEED_WITH_DISCOUNT_WITH_BUCKET_LOAD_IN_PROGRESS',
    CannotProceedWithDiscountWithNotApprovedAgreement = 'CANNOT_PROCEED_WITH_DISCOUNT_WITH_NOT_APPROVED_AGREEMENT',
    CannotProceedWithSuspendedDiscount = 'CANNOT_PROCEED_WITH_SUSPENDED_DISCOUNT',
    CannotProceedWithExpiredDiscount = 'CANNOT_PROCEED_WITH_EXPIRED_DISCOUNT',
    CannotProceedWithOnlineDiscountWithNotPassedTest = 'CANNOT_PROCEED_WITH_ONLINE_DISCOUNT_WITH_NOT_PASSED_TEST',
    MaxNumberOfPublishableDiscountsReached = 'MAX_NUMBER_OF_PUBLISHABLE_DISCOUNTS_REACHED',
    DiscountCannotHaveMoreThanTwoCategories = 'DISCOUNT_CANNOT_HAVE_MORE_THAN_TWO_CATEGORIES',
    AgreementNotApprovableForWrongMandatoryDocuments = 'AGREEMENT_NOT_APPROVABLE_FOR_WRONG_MANDATORY_DOCUMENTS',
    AgreementNotFound = 'AGREEMENT_NOT_FOUND',
    DiscountNotFound = 'DISCOUNT_NOT_FOUND',
    ProfileNotFound = 'PROFILE_NOT_FOUND',
    DocumentNotFound = 'DOCUMENT_NOT_FOUND',
    DocumentTypeNotValid = 'DOCUMENT_TYPE_NOT_VALID',
    DiscountNotRelatedToAgreementProvided = 'DISCOUNT_NOT_RELATED_TO_AGREEMENT_PROVIDED',
    CannotTestDiscountsWithOfflineMerchants = 'CANNOT_TEST_DISCOUNTS_WITH_OFFLINE_MERCHANTS',
    CannotUnpublishDiscountNotPublished = 'CANNOT_UNPUBLISH_DISCOUNT_NOT_PUBLISHED',
    CannotSuspendDiscountNotPublished = 'CANNOT_SUSPEND_DISCOUNT_NOT_PUBLISHED',
    DiscountCannotReferenceToMissingBucketFileForDiscountWithBucket = 'DISCOUNT_CANNOT_REFERENCE_TO_MISSING_BUCKET_FILE_FOR_DISCOUNT_WITH_BUCKET',
    CannotSetDiscountStateForDiscountNotInTestPending = 'CANNOT_SET_DISCOUNT_STATE_FOR_DISCOUNT_NOT_IN_TEST_PENDING',
    CannotGetBucketCodeForDiscountNotInTestPending = 'CANNOT_GET_BUCKET_CODE_FOR_DISCOUNT_NOT_IN_TEST_PENDING',
    CannotGetBucketCodeForDiscountNoBucket = 'CANNOT_GET_BUCKET_CODE_FOR_DISCOUNT_NO_BUCKET',
    CannotLoadBucketCodeForDiscountNoBucket = 'CANNOT_LOAD_BUCKET_CODE_FOR_DISCOUNT_NO_BUCKET',
    MaxAllowedBucketCodeLengthNotRespected = 'MAX_ALLOWED_BUCKET_CODE_LENGTH_NOT_RESPECTED',
    BucketCodesMustBeAlphanumWithAtLeastOneDigitAndOneChar = 'BUCKET_CODES_MUST_BE_ALPHANUM_WITH_AT_LEAST_ONE_DIGIT_AND_ONE_CHAR',
    CannotProceedAgreementNotInPending = 'CANNOT_PROCEED_AGREEMENT_NOT_IN_PENDING',
    AgreementNotAssignedToCurrentUser = 'AGREEMENT_NOT_ASSIGNED_TO_CURRENT_USER',
    MandatoryDocumentAreMissing = 'MANDATORY_DOCUMENT_ARE_MISSING',
    AgreementAlreadyAssignedToCurrentUser = 'AGREEMENT_ALREADY_ASSIGNED_TO_CURRENT_USER',
    AgreementNoLongerAssigned = 'AGREEMENT_NO_LONGER_ASSIGNED',
    AgreementUserNotFound = 'AGREEMENT_USER_NOT_FOUND',
    CannotRetrieveTokenForProfileNotApi = 'CANNOT_RETRIEVE_TOKEN_FOR_PROFILE_NOT_API',
    TokenParameterNotValid = 'TOKEN_PARAMETER_NOT_VALID',
    PdfNameOrExtensionNotValid = 'PDF_NAME_OR_EXTENSION_NOT_VALID',
    PdfRenderingError = 'PDF_RENDERING_ERROR',
    CsvNameOrExtensionNotValid = 'CSV_NAME_OR_EXTENSION_NOT_VALID',
    CsvDataNotValid = 'CSV_DATA_NOT_VALID',
    ImageDataNotValid = 'IMAGE_DATA_NOT_VALID',
    ImageNameOrExtensionNotValid = 'IMAGE_NAME_OR_EXTENSION_NOT_VALID',
    ImageDimensionNotValid = 'IMAGE_DIMENSION_NOT_VALID',
    ImageAzureUploadError = 'IMAGE_AZURE_UPLOAD_ERROR',
    AdhesionDocumentNotRequiredForPa = 'ADHESION_DOCUMENT_NOT_REQUIRED_FOR_PA',
    CannotUpdateDiscountBucketWhileProcessingIsRunning = 'CANNOT_UPDATE_DISCOUNT_BUCKET_WHILE_PROCESSING_IS_RUNNING',
    CannotLoadBucketForNotRespectedMinimumBound = 'CANNOT_LOAD_BUCKET_FOR_NOT_RESPECTED_MINIMUM_BOUND',
    ProfileAlreadyExistsForAgreementProvided = 'PROFILE_ALREADY_EXISTS_FOR_AGREEMENT_PROVIDED',
    CannotBindMoreThanTenOrganizations = 'CANNOT_BIND_MORE_THAN_TEN_ORGANIZATIONS'
}

/**
 * 
 * @export
 * @interface GeolocationToken
 */
export interface GeolocationToken {
    /**
     * 
     * @type {string}
     * @memberof GeolocationToken
     */
    token: string;
}
/**
 * 
 * @export
 * @interface HelpRequest
 */
export interface HelpRequest {
    /**
     * 
     * @type {string}
     * @memberof HelpRequest
     */
    category: HelpRequestCategoryEnum;
    /**
     * 
     * @type {string}
     * @memberof HelpRequest
     */
    topic?: string;
    /**
     * 
     * @type {string}
     * @memberof HelpRequest
     */
    message: string;
}

/**
    * @export
    * @enum {string}
    */
export enum HelpRequestCategoryEnum {
    Access = 'Access',
    DataFilling = 'DataFilling',
    Discounts = 'Discounts',
    Documents = 'Documents',
    TechnicalProblem = 'TechnicalProblem',
    CgnOwnerReporting = 'CgnOwnerReporting',
    Suggestions = 'Suggestions',
    Other = 'Other'
}

/**
 * 
 * @export
 * @enum {string}
 */
export enum ImageErrorCode {
    InvalidImageType = 'INVALID_IMAGE_TYPE',
    InvalidDimension = 'INVALID_DIMENSION',
    Generic = 'GENERIC',
    ImageSizeExceeded = 'IMAGE_SIZE_EXCEEDED'
}

/**
 * 
 * @export
 * @interface OfflineChannel
 */
export interface OfflineChannel extends SalesChannel {
    /**
     * 
     * @type {string}
     * @memberof OfflineChannel
     */
    websiteUrl?: string;
    /**
     * 
     * @type {Array<Address>}
     * @memberof OfflineChannel
     */
    addresses: Array<Address>;
    /**
     * 
     * @type {boolean}
     * @memberof OfflineChannel
     */
    allNationalAddresses?: boolean;
}
/**
 * 
 * @export
 * @interface OfflineChannelAllOf
 */
export interface OfflineChannelAllOf {
    /**
     * 
     * @type {string}
     * @memberof OfflineChannelAllOf
     */
    websiteUrl?: string;
    /**
     * 
     * @type {Array<Address>}
     * @memberof OfflineChannelAllOf
     */
    addresses: Array<Address>;
    /**
     * 
     * @type {boolean}
     * @memberof OfflineChannelAllOf
     */
    allNationalAddresses?: boolean;
}
/**
 * 
 * @export
 * @interface OnlineChannel
 */
export interface OnlineChannel extends SalesChannel {
    /**
     * 
     * @type {string}
     * @memberof OnlineChannel
     */
    websiteUrl: string;
    /**
     * 
     * @type {DiscountCodeType}
     * @memberof OnlineChannel
     */
    discountCodeType: DiscountCodeType;
}
/**
 * 
 * @export
 * @interface OnlineChannelAllOf
 */
export interface OnlineChannelAllOf {
    /**
     * 
     * @type {string}
     * @memberof OnlineChannelAllOf
     */
    websiteUrl: string;
    /**
     * 
     * @type {DiscountCodeType}
     * @memberof OnlineChannelAllOf
     */
    discountCodeType: DiscountCodeType;
}
/**
 * 
 * @export
 * @interface PendingAgreement
 */
export interface PendingAgreement extends Agreement {
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum ProductCategory {
    BankingServices = 'BankingServices',
    CultureAndEntertainment = 'CultureAndEntertainment',
    Health = 'Health',
    Home = 'Home',
    JobOffers = 'JobOffers',
    Learning = 'Learning',
    Sports = 'Sports',
    SustainableMobility = 'SustainableMobility',
    TelephonyAndInternet = 'TelephonyAndInternet',
    Travelling = 'Travelling'
}

/**
 * 
 * @export
 * @interface Profile
 */
export interface Profile {
    /**
     * 
     * @type {string}
     * @memberof Profile
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof Profile
     */
    agreementId: string;
    /**
     * 
     * @type {string}
     * @memberof Profile
     */
    fullName: string;
    /**
     * 
     * @type {string}
     * @memberof Profile
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof Profile
     */
    name_en?: string;
    /**
     * 
     * @type {string}
     * @memberof Profile
     */
    name_de?: string;
    /**
     * 
     * @type {string}
     * @memberof Profile
     */
    taxCodeOrVat: string;
    /**
     * 
     * @type {string}
     * @memberof Profile
     */
    pecAddress: string;
    /**
     * 
     * @type {string}
     * @memberof Profile
     */
    legalOffice: string;
    /**
     * 
     * @type {string}
     * @memberof Profile
     */
    telephoneNumber: string;
    /**
     * 
     * @type {string}
     * @memberof Profile
     */
    legalRepresentativeFullName: string;
    /**
     * 
     * @type {string}
     * @memberof Profile
     */
    legalRepresentativeTaxCode: string;
    /**
     * 
     * @type {Referent}
     * @memberof Profile
     */
    referent: Referent;
    /**
     * 
     * @type {string}
     * @memberof Profile
     */
    description: string;
    /**
     * 
     * @type {string}
     * @memberof Profile
     */
    description_en: string;
    /**
     * 
     * @type {string}
     * @memberof Profile
     */
    description_de: string;
    /**
     * 
     * @type {SalesChannel}
     * @memberof Profile
     */
    salesChannel: SalesChannel;
    /**
     * 
     * @type {Array<Referent>}
     * @memberof Profile
     */
    secondaryReferents?: Array<Referent>;
}
/**
 * 
 * @export
 * @interface Referent
 */
export interface Referent {
    /**
     * 
     * @type {string}
     * @memberof Referent
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof Referent
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof Referent
     */
    role: string;
    /**
     * 
     * @type {string}
     * @memberof Referent
     */
    emailAddress: string;
    /**
     * 
     * @type {string}
     * @memberof Referent
     */
    telephoneNumber: string;
}
/**
 * 
 * @export
 * @interface RejectedAgreement
 */
export interface RejectedAgreement extends Agreement {
    /**
     * 
     * @type {string}
     * @memberof RejectedAgreement
     */
    reasonMessage: string;
}
/**
 * 
 * @export
 * @interface RejectedAgreementAllOf
 */
export interface RejectedAgreementAllOf {
    /**
     * 
     * @type {string}
     * @memberof RejectedAgreementAllOf
     */
    reasonMessage: string;
}
/**
 * 
 * @export
 * @interface SalesChannel
 */
export interface SalesChannel {
    /**
     * 
     * @type {SalesChannelType}
     * @memberof SalesChannel
     */
    channelType: SalesChannelType;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum SalesChannelType {
    OnlineChannel = 'OnlineChannel',
    OfflineChannel = 'OfflineChannel',
    BothChannels = 'BothChannels'
}

/**
 * 
 * @export
 * @interface UpdateDiscount
 */
export interface UpdateDiscount {
    /**
     * 
     * @type {string}
     * @memberof UpdateDiscount
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateDiscount
     */
    name_en: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateDiscount
     */
    name_de: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateDiscount
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateDiscount
     */
    description_en?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateDiscount
     */
    description_de?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateDiscount
     */
    startDate: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateDiscount
     */
    endDate: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateDiscount
     */
    discount?: number;
    /**
     * 
     * @type {Array<ProductCategory>}
     * @memberof UpdateDiscount
     */
    productCategories: Array<ProductCategory>;
    /**
     * 
     * @type {string}
     * @memberof UpdateDiscount
     */
    condition?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateDiscount
     */
    condition_en?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateDiscount
     */
    condition_de?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateDiscount
     */
    staticCode?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateDiscount
     */
    visibleOnEyca?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UpdateDiscount
     */
    eycaLandingPageUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateDiscount
     */
    landingPageUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateDiscount
     */
    landingPageReferrer?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateDiscount
     */
    lastBucketCodeLoadUid?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateDiscount
     */
    lastBucketCodeLoadFileName?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateDiscount
     */
    discountUrl?: string;
}
/**
 * 
 * @export
 * @interface UpdateProfile
 */
export interface UpdateProfile {
    /**
     * 
     * @type {string}
     * @memberof UpdateProfile
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateProfile
     */
    name_en?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateProfile
     */
    name_de?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateProfile
     */
    pecAddress: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateProfile
     */
    legalOffice: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateProfile
     */
    telephoneNumber: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateProfile
     */
    legalRepresentativeFullName: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateProfile
     */
    legalRepresentativeTaxCode: string;
    /**
     * 
     * @type {UpdateReferent}
     * @memberof UpdateProfile
     */
    referent: UpdateReferent;
    /**
     * 
     * @type {string}
     * @memberof UpdateProfile
     */
    description: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateProfile
     */
    description_en: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateProfile
     */
    description_de: string;
    /**
     * 
     * @type {SalesChannel}
     * @memberof UpdateProfile
     */
    salesChannel: SalesChannel;
    /**
     * 
     * @type {Array<UpdateReferent>}
     * @memberof UpdateProfile
     */
    secondaryReferents?: Array<UpdateReferent>;
}
/**
 * 
 * @export
 * @interface UpdateReferent
 */
export interface UpdateReferent {
    /**
     * 
     * @type {string}
     * @memberof UpdateReferent
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateReferent
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateReferent
     */
    role: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateReferent
     */
    emailAddress: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateReferent
     */
    telephoneNumber: string;
}
/**
 * 
 * @export
 * @interface UploadedImage
 */
export interface UploadedImage {
    /**
     * 
     * @type {string}
     * @memberof UploadedImage
     */
    imageUrl: string;
}

/**
 * AgreementApi - axios parameter creator
 * @export
 */
export const AgreementApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create an agreement
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAgreement: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/agreements`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication UserRole required
            await setApiKeyToObject(localVarHeaderParameter, "X-CGN-USER-ROLE", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Request agreement approval
         * @param {string} agreementId Agreement id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestApproval: async (agreementId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'agreementId' is not null or undefined
            assertParamExists('requestApproval', 'agreementId', agreementId)
            const localVarPath = `/agreements/{agreementId}/approval`
                .replace(`{${"agreementId"}}`, encodeURIComponent(String(agreementId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication UserRole required
            await setApiKeyToObject(localVarHeaderParameter, "X-CGN-USER-ROLE", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Upload an image
         * @param {string} agreementId Agreement id
         * @param {any} image 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadImage: async (agreementId: string, image: any, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'agreementId' is not null or undefined
            assertParamExists('uploadImage', 'agreementId', agreementId)
            // verify required parameter 'image' is not null or undefined
            assertParamExists('uploadImage', 'image', image)
            const localVarPath = `/agreements/{agreementId}/images`
                .replace(`{${"agreementId"}}`, encodeURIComponent(String(agreementId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication UserRole required
            await setApiKeyToObject(localVarHeaderParameter, "X-CGN-USER-ROLE", configuration)


            if (image !== undefined) { 
                localVarFormParams.append('image', image as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AgreementApi - functional programming interface
 * @export
 */
export const AgreementApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AgreementApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create an agreement
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createAgreement(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Agreement>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createAgreement(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Request agreement approval
         * @param {string} agreementId Agreement id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async requestApproval(agreementId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.requestApproval(agreementId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Upload an image
         * @param {string} agreementId Agreement id
         * @param {any} image 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadImage(agreementId: string, image: any, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UploadedImage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadImage(agreementId, image, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AgreementApi - factory interface
 * @export
 */
export const AgreementApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AgreementApiFp(configuration)
    return {
        /**
         * 
         * @summary Create an agreement
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAgreement(options?: any): AxiosPromise<Agreement> {
            return localVarFp.createAgreement(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Request agreement approval
         * @param {string} agreementId Agreement id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestApproval(agreementId: string, options?: any): AxiosPromise<void> {
            return localVarFp.requestApproval(agreementId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Upload an image
         * @param {string} agreementId Agreement id
         * @param {any} image 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadImage(agreementId: string, image: any, options?: any): AxiosPromise<UploadedImage> {
            return localVarFp.uploadImage(agreementId, image, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AgreementApi - object-oriented interface
 * @export
 * @class AgreementApi
 * @extends {BaseAPI}
 */
export class AgreementApi extends BaseAPI {
    /**
     * 
     * @summary Create an agreement
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgreementApi
     */
    public createAgreement(options?: any) {
        return AgreementApiFp(this.configuration).createAgreement(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Request agreement approval
     * @param {string} agreementId Agreement id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgreementApi
     */
    public requestApproval(agreementId: string, options?: any) {
        return AgreementApiFp(this.configuration).requestApproval(agreementId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Upload an image
     * @param {string} agreementId Agreement id
     * @param {any} image 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgreementApi
     */
    public uploadImage(agreementId: string, image: any, options?: any) {
        return AgreementApiFp(this.configuration).uploadImage(agreementId, image, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ApiTokenApi - axios parameter creator
 * @export
 */
export const ApiTokenApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Retrieve or Create API Tokens
         * @param {string} agreementId Agreement id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTokens: async (agreementId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'agreementId' is not null or undefined
            assertParamExists('getTokens', 'agreementId', agreementId)
            const localVarPath = `/agreements/{agreementId}/api-tokens`
                .replace(`{${"agreementId"}}`, encodeURIComponent(String(agreementId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication UserRole required
            await setApiKeyToObject(localVarHeaderParameter, "X-CGN-USER-ROLE", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Regenerate Api Token
         * @param {string} agreementId Agreement id
         * @param {'primary' | 'secondary'} tokenType Token type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        regenerateToken: async (agreementId: string, tokenType: 'primary' | 'secondary', options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'agreementId' is not null or undefined
            assertParamExists('regenerateToken', 'agreementId', agreementId)
            // verify required parameter 'tokenType' is not null or undefined
            assertParamExists('regenerateToken', 'tokenType', tokenType)
            const localVarPath = `/agreements/{agreementId}/api-tokens/{tokenType}`
                .replace(`{${"agreementId"}}`, encodeURIComponent(String(agreementId)))
                .replace(`{${"tokenType"}}`, encodeURIComponent(String(tokenType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication UserRole required
            await setApiKeyToObject(localVarHeaderParameter, "X-CGN-USER-ROLE", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ApiTokenApi - functional programming interface
 * @export
 */
export const ApiTokenApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ApiTokenApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Retrieve or Create API Tokens
         * @param {string} agreementId Agreement id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTokens(agreementId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiTokens>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTokens(agreementId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Regenerate Api Token
         * @param {string} agreementId Agreement id
         * @param {'primary' | 'secondary'} tokenType Token type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async regenerateToken(agreementId: string, tokenType: 'primary' | 'secondary', options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiTokens>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.regenerateToken(agreementId, tokenType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ApiTokenApi - factory interface
 * @export
 */
export const ApiTokenApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ApiTokenApiFp(configuration)
    return {
        /**
         * 
         * @summary Retrieve or Create API Tokens
         * @param {string} agreementId Agreement id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTokens(agreementId: string, options?: any): AxiosPromise<ApiTokens> {
            return localVarFp.getTokens(agreementId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Regenerate Api Token
         * @param {string} agreementId Agreement id
         * @param {'primary' | 'secondary'} tokenType Token type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        regenerateToken(agreementId: string, tokenType: 'primary' | 'secondary', options?: any): AxiosPromise<ApiTokens> {
            return localVarFp.regenerateToken(agreementId, tokenType, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ApiTokenApi - object-oriented interface
 * @export
 * @class ApiTokenApi
 * @extends {BaseAPI}
 */
export class ApiTokenApi extends BaseAPI {
    /**
     * 
     * @summary Retrieve or Create API Tokens
     * @param {string} agreementId Agreement id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiTokenApi
     */
    public getTokens(agreementId: string, options?: any) {
        return ApiTokenApiFp(this.configuration).getTokens(agreementId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Regenerate Api Token
     * @param {string} agreementId Agreement id
     * @param {'primary' | 'secondary'} tokenType Token type
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiTokenApi
     */
    public regenerateToken(agreementId: string, tokenType: 'primary' | 'secondary', options?: any) {
        return ApiTokenApiFp(this.configuration).regenerateToken(agreementId, tokenType, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * BucketApi - axios parameter creator
 * @export
 */
export const BucketApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary uploadBucket
         * @param {string} agreementId Agreement id
         * @param {any} document 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadBucket: async (agreementId: string, document: any, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'agreementId' is not null or undefined
            assertParamExists('uploadBucket', 'agreementId', agreementId)
            // verify required parameter 'document' is not null or undefined
            assertParamExists('uploadBucket', 'document', document)
            const localVarPath = `/agreements/{agreementId}/discounts/bucket`
                .replace(`{${"agreementId"}}`, encodeURIComponent(String(agreementId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication UserRole required
            await setApiKeyToObject(localVarHeaderParameter, "X-CGN-USER-ROLE", configuration)


            if (document !== undefined) { 
                localVarFormParams.append('document', document as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BucketApi - functional programming interface
 * @export
 */
export const BucketApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BucketApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary uploadBucket
         * @param {string} agreementId Agreement id
         * @param {any} document 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadBucket(agreementId: string, document: any, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BucketLoad>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadBucket(agreementId, document, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BucketApi - factory interface
 * @export
 */
export const BucketApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BucketApiFp(configuration)
    return {
        /**
         * 
         * @summary uploadBucket
         * @param {string} agreementId Agreement id
         * @param {any} document 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadBucket(agreementId: string, document: any, options?: any): AxiosPromise<BucketLoad> {
            return localVarFp.uploadBucket(agreementId, document, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BucketApi - object-oriented interface
 * @export
 * @class BucketApi
 * @extends {BaseAPI}
 */
export class BucketApi extends BaseAPI {
    /**
     * 
     * @summary uploadBucket
     * @param {string} agreementId Agreement id
     * @param {any} document 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BucketApi
     */
    public uploadBucket(agreementId: string, document: any, options?: any) {
        return BucketApiFp(this.configuration).uploadBucket(agreementId, document, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DiscountApi - axios parameter creator
 * @export
 */
export const DiscountApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create a discount
         * @param {string} agreementId Agreement id
         * @param {CreateDiscount} discount Discount to create
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDiscount: async (agreementId: string, discount: CreateDiscount, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'agreementId' is not null or undefined
            assertParamExists('createDiscount', 'agreementId', agreementId)
            // verify required parameter 'discount' is not null or undefined
            assertParamExists('createDiscount', 'discount', discount)
            const localVarPath = `/agreements/{agreementId}/discounts`
                .replace(`{${"agreementId"}}`, encodeURIComponent(String(agreementId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication UserRole required
            await setApiKeyToObject(localVarHeaderParameter, "X-CGN-USER-ROLE", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(discount, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete a discount
         * @param {string} agreementId Agreement id
         * @param {string} discountId Discount id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDiscount: async (agreementId: string, discountId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'agreementId' is not null or undefined
            assertParamExists('deleteDiscount', 'agreementId', agreementId)
            // verify required parameter 'discountId' is not null or undefined
            assertParamExists('deleteDiscount', 'discountId', discountId)
            const localVarPath = `/agreements/{agreementId}/discounts/{discountId}`
                .replace(`{${"agreementId"}}`, encodeURIComponent(String(agreementId)))
                .replace(`{${"discountId"}}`, encodeURIComponent(String(discountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication UserRole required
            await setApiKeyToObject(localVarHeaderParameter, "X-CGN-USER-ROLE", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get discount by id
         * @param {string} agreementId Agreement id
         * @param {string} discountId Discount id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDiscountById: async (agreementId: string, discountId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'agreementId' is not null or undefined
            assertParamExists('getDiscountById', 'agreementId', agreementId)
            // verify required parameter 'discountId' is not null or undefined
            assertParamExists('getDiscountById', 'discountId', discountId)
            const localVarPath = `/agreements/{agreementId}/discounts/{discountId}`
                .replace(`{${"agreementId"}}`, encodeURIComponent(String(agreementId)))
                .replace(`{${"discountId"}}`, encodeURIComponent(String(discountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication UserRole required
            await setApiKeyToObject(localVarHeaderParameter, "X-CGN-USER-ROLE", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get list of discounts
         * @param {string} agreementId Agreement id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDiscounts: async (agreementId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'agreementId' is not null or undefined
            assertParamExists('getDiscounts', 'agreementId', agreementId)
            const localVarPath = `/agreements/{agreementId}/discounts`
                .replace(`{${"agreementId"}}`, encodeURIComponent(String(agreementId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication UserRole required
            await setApiKeyToObject(localVarHeaderParameter, "X-CGN-USER-ROLE", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Publish a discount
         * @param {string} agreementId Agreement id
         * @param {string} discountId Discount id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publishDiscount: async (agreementId: string, discountId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'agreementId' is not null or undefined
            assertParamExists('publishDiscount', 'agreementId', agreementId)
            // verify required parameter 'discountId' is not null or undefined
            assertParamExists('publishDiscount', 'discountId', discountId)
            const localVarPath = `/agreements/{agreementId}/discounts/{discountId}/publishing`
                .replace(`{${"agreementId"}}`, encodeURIComponent(String(agreementId)))
                .replace(`{${"discountId"}}`, encodeURIComponent(String(discountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication UserRole required
            await setApiKeyToObject(localVarHeaderParameter, "X-CGN-USER-ROLE", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Set a discount ready to be tested
         * @param {string} agreementId Agreement id
         * @param {string} discountId Discount id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testDiscount: async (agreementId: string, discountId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'agreementId' is not null or undefined
            assertParamExists('testDiscount', 'agreementId', agreementId)
            // verify required parameter 'discountId' is not null or undefined
            assertParamExists('testDiscount', 'discountId', discountId)
            const localVarPath = `/agreements/{agreementId}/discounts/{discountId}/testing`
                .replace(`{${"agreementId"}}`, encodeURIComponent(String(agreementId)))
                .replace(`{${"discountId"}}`, encodeURIComponent(String(discountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication UserRole required
            await setApiKeyToObject(localVarHeaderParameter, "X-CGN-USER-ROLE", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Unpublish a discount making it a draft
         * @param {string} agreementId Agreement id
         * @param {string} discountId Discount id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unpublishDiscount: async (agreementId: string, discountId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'agreementId' is not null or undefined
            assertParamExists('unpublishDiscount', 'agreementId', agreementId)
            // verify required parameter 'discountId' is not null or undefined
            assertParamExists('unpublishDiscount', 'discountId', discountId)
            const localVarPath = `/agreements/{agreementId}/discounts/{discountId}/unpublishing`
                .replace(`{${"agreementId"}}`, encodeURIComponent(String(agreementId)))
                .replace(`{${"discountId"}}`, encodeURIComponent(String(discountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication UserRole required
            await setApiKeyToObject(localVarHeaderParameter, "X-CGN-USER-ROLE", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a discount
         * @param {string} agreementId Agreement id
         * @param {string} discountId Discount id
         * @param {UpdateDiscount} discount Discount to update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDiscount: async (agreementId: string, discountId: string, discount: UpdateDiscount, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'agreementId' is not null or undefined
            assertParamExists('updateDiscount', 'agreementId', agreementId)
            // verify required parameter 'discountId' is not null or undefined
            assertParamExists('updateDiscount', 'discountId', discountId)
            // verify required parameter 'discount' is not null or undefined
            assertParamExists('updateDiscount', 'discount', discount)
            const localVarPath = `/agreements/{agreementId}/discounts/{discountId}`
                .replace(`{${"agreementId"}}`, encodeURIComponent(String(agreementId)))
                .replace(`{${"discountId"}}`, encodeURIComponent(String(discountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication UserRole required
            await setApiKeyToObject(localVarHeaderParameter, "X-CGN-USER-ROLE", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(discount, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DiscountApi - functional programming interface
 * @export
 */
export const DiscountApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DiscountApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create a discount
         * @param {string} agreementId Agreement id
         * @param {CreateDiscount} discount Discount to create
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createDiscount(agreementId: string, discount: CreateDiscount, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Discount>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createDiscount(agreementId, discount, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete a discount
         * @param {string} agreementId Agreement id
         * @param {string} discountId Discount id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteDiscount(agreementId: string, discountId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteDiscount(agreementId, discountId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get discount by id
         * @param {string} agreementId Agreement id
         * @param {string} discountId Discount id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDiscountById(agreementId: string, discountId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Discount>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDiscountById(agreementId, discountId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get list of discounts
         * @param {string} agreementId Agreement id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDiscounts(agreementId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Discounts>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDiscounts(agreementId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Publish a discount
         * @param {string} agreementId Agreement id
         * @param {string} discountId Discount id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async publishDiscount(agreementId: string, discountId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.publishDiscount(agreementId, discountId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Set a discount ready to be tested
         * @param {string} agreementId Agreement id
         * @param {string} discountId Discount id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testDiscount(agreementId: string, discountId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testDiscount(agreementId, discountId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Unpublish a discount making it a draft
         * @param {string} agreementId Agreement id
         * @param {string} discountId Discount id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async unpublishDiscount(agreementId: string, discountId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.unpublishDiscount(agreementId, discountId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update a discount
         * @param {string} agreementId Agreement id
         * @param {string} discountId Discount id
         * @param {UpdateDiscount} discount Discount to update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateDiscount(agreementId: string, discountId: string, discount: UpdateDiscount, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Discount>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateDiscount(agreementId, discountId, discount, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DiscountApi - factory interface
 * @export
 */
export const DiscountApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DiscountApiFp(configuration)
    return {
        /**
         * 
         * @summary Create a discount
         * @param {string} agreementId Agreement id
         * @param {CreateDiscount} discount Discount to create
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDiscount(agreementId: string, discount: CreateDiscount, options?: any): AxiosPromise<Discount> {
            return localVarFp.createDiscount(agreementId, discount, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete a discount
         * @param {string} agreementId Agreement id
         * @param {string} discountId Discount id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDiscount(agreementId: string, discountId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteDiscount(agreementId, discountId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get discount by id
         * @param {string} agreementId Agreement id
         * @param {string} discountId Discount id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDiscountById(agreementId: string, discountId: string, options?: any): AxiosPromise<Discount> {
            return localVarFp.getDiscountById(agreementId, discountId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get list of discounts
         * @param {string} agreementId Agreement id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDiscounts(agreementId: string, options?: any): AxiosPromise<Discounts> {
            return localVarFp.getDiscounts(agreementId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Publish a discount
         * @param {string} agreementId Agreement id
         * @param {string} discountId Discount id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publishDiscount(agreementId: string, discountId: string, options?: any): AxiosPromise<void> {
            return localVarFp.publishDiscount(agreementId, discountId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Set a discount ready to be tested
         * @param {string} agreementId Agreement id
         * @param {string} discountId Discount id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testDiscount(agreementId: string, discountId: string, options?: any): AxiosPromise<void> {
            return localVarFp.testDiscount(agreementId, discountId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Unpublish a discount making it a draft
         * @param {string} agreementId Agreement id
         * @param {string} discountId Discount id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unpublishDiscount(agreementId: string, discountId: string, options?: any): AxiosPromise<void> {
            return localVarFp.unpublishDiscount(agreementId, discountId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a discount
         * @param {string} agreementId Agreement id
         * @param {string} discountId Discount id
         * @param {UpdateDiscount} discount Discount to update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDiscount(agreementId: string, discountId: string, discount: UpdateDiscount, options?: any): AxiosPromise<Discount> {
            return localVarFp.updateDiscount(agreementId, discountId, discount, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DiscountApi - object-oriented interface
 * @export
 * @class DiscountApi
 * @extends {BaseAPI}
 */
export class DiscountApi extends BaseAPI {
    /**
     * 
     * @summary Create a discount
     * @param {string} agreementId Agreement id
     * @param {CreateDiscount} discount Discount to create
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DiscountApi
     */
    public createDiscount(agreementId: string, discount: CreateDiscount, options?: any) {
        return DiscountApiFp(this.configuration).createDiscount(agreementId, discount, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete a discount
     * @param {string} agreementId Agreement id
     * @param {string} discountId Discount id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DiscountApi
     */
    public deleteDiscount(agreementId: string, discountId: string, options?: any) {
        return DiscountApiFp(this.configuration).deleteDiscount(agreementId, discountId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get discount by id
     * @param {string} agreementId Agreement id
     * @param {string} discountId Discount id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DiscountApi
     */
    public getDiscountById(agreementId: string, discountId: string, options?: any) {
        return DiscountApiFp(this.configuration).getDiscountById(agreementId, discountId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get list of discounts
     * @param {string} agreementId Agreement id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DiscountApi
     */
    public getDiscounts(agreementId: string, options?: any) {
        return DiscountApiFp(this.configuration).getDiscounts(agreementId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Publish a discount
     * @param {string} agreementId Agreement id
     * @param {string} discountId Discount id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DiscountApi
     */
    public publishDiscount(agreementId: string, discountId: string, options?: any) {
        return DiscountApiFp(this.configuration).publishDiscount(agreementId, discountId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Set a discount ready to be tested
     * @param {string} agreementId Agreement id
     * @param {string} discountId Discount id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DiscountApi
     */
    public testDiscount(agreementId: string, discountId: string, options?: any) {
        return DiscountApiFp(this.configuration).testDiscount(agreementId, discountId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Unpublish a discount making it a draft
     * @param {string} agreementId Agreement id
     * @param {string} discountId Discount id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DiscountApi
     */
    public unpublishDiscount(agreementId: string, discountId: string, options?: any) {
        return DiscountApiFp(this.configuration).unpublishDiscount(agreementId, discountId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a discount
     * @param {string} agreementId Agreement id
     * @param {string} discountId Discount id
     * @param {UpdateDiscount} discount Discount to update
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DiscountApi
     */
    public updateDiscount(agreementId: string, discountId: string, discount: UpdateDiscount, options?: any) {
        return DiscountApiFp(this.configuration).updateDiscount(agreementId, discountId, discount, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DiscountBucketLoadingProgressApi - axios parameter creator
 * @export
 */
export const DiscountBucketLoadingProgressApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get the loading progress for the last bucket of codes of a discount
         * @param {string} agreementId Agreement id
         * @param {string} discountId Discount id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDiscountBucketCodeLoadingProgess: async (agreementId: string, discountId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'agreementId' is not null or undefined
            assertParamExists('getDiscountBucketCodeLoadingProgess', 'agreementId', agreementId)
            // verify required parameter 'discountId' is not null or undefined
            assertParamExists('getDiscountBucketCodeLoadingProgess', 'discountId', discountId)
            const localVarPath = `/agreements/{agreementId}/discounts/{discountId}/bucket-loading-progress`
                .replace(`{${"agreementId"}}`, encodeURIComponent(String(agreementId)))
                .replace(`{${"discountId"}}`, encodeURIComponent(String(discountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication UserRole required
            await setApiKeyToObject(localVarHeaderParameter, "X-CGN-USER-ROLE", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DiscountBucketLoadingProgressApi - functional programming interface
 * @export
 */
export const DiscountBucketLoadingProgressApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DiscountBucketLoadingProgressApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get the loading progress for the last bucket of codes of a discount
         * @param {string} agreementId Agreement id
         * @param {string} discountId Discount id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDiscountBucketCodeLoadingProgess(agreementId: string, discountId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DiscountBucketCodeLoadingProgess>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDiscountBucketCodeLoadingProgess(agreementId, discountId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DiscountBucketLoadingProgressApi - factory interface
 * @export
 */
export const DiscountBucketLoadingProgressApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DiscountBucketLoadingProgressApiFp(configuration)
    return {
        /**
         * 
         * @summary Get the loading progress for the last bucket of codes of a discount
         * @param {string} agreementId Agreement id
         * @param {string} discountId Discount id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDiscountBucketCodeLoadingProgess(agreementId: string, discountId: string, options?: any): AxiosPromise<DiscountBucketCodeLoadingProgess> {
            return localVarFp.getDiscountBucketCodeLoadingProgess(agreementId, discountId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DiscountBucketLoadingProgressApi - object-oriented interface
 * @export
 * @class DiscountBucketLoadingProgressApi
 * @extends {BaseAPI}
 */
export class DiscountBucketLoadingProgressApi extends BaseAPI {
    /**
     * 
     * @summary Get the loading progress for the last bucket of codes of a discount
     * @param {string} agreementId Agreement id
     * @param {string} discountId Discount id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DiscountBucketLoadingProgressApi
     */
    public getDiscountBucketCodeLoadingProgess(agreementId: string, discountId: string, options?: any) {
        return DiscountBucketLoadingProgressApiFp(this.configuration).getDiscountBucketCodeLoadingProgess(agreementId, discountId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DocumentApi - axios parameter creator
 * @export
 */
export const DocumentApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Delete a document
         * @param {string} agreementId Agreement id
         * @param {string} documentType Document type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDocument: async (agreementId: string, documentType: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'agreementId' is not null or undefined
            assertParamExists('deleteDocument', 'agreementId', agreementId)
            // verify required parameter 'documentType' is not null or undefined
            assertParamExists('deleteDocument', 'documentType', documentType)
            const localVarPath = `/agreements/{agreementId}/documents/{documentType}`
                .replace(`{${"agreementId"}}`, encodeURIComponent(String(agreementId)))
                .replace(`{${"documentType"}}`, encodeURIComponent(String(documentType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication UserRole required
            await setApiKeyToObject(localVarHeaderParameter, "X-CGN-USER-ROLE", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get documents
         * @param {string} agreementId Agreement id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocuments: async (agreementId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'agreementId' is not null or undefined
            assertParamExists('getDocuments', 'agreementId', agreementId)
            const localVarPath = `/agreements/{agreementId}/documents`
                .replace(`{${"agreementId"}}`, encodeURIComponent(String(agreementId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication UserRole required
            await setApiKeyToObject(localVarHeaderParameter, "X-CGN-USER-ROLE", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Upload a document
         * @param {string} agreementId Agreement id
         * @param {string} documentType Document type
         * @param {any} document 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadDocument: async (agreementId: string, documentType: string, document: any, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'agreementId' is not null or undefined
            assertParamExists('uploadDocument', 'agreementId', agreementId)
            // verify required parameter 'documentType' is not null or undefined
            assertParamExists('uploadDocument', 'documentType', documentType)
            // verify required parameter 'document' is not null or undefined
            assertParamExists('uploadDocument', 'document', document)
            const localVarPath = `/agreements/{agreementId}/documents/{documentType}`
                .replace(`{${"agreementId"}}`, encodeURIComponent(String(agreementId)))
                .replace(`{${"documentType"}}`, encodeURIComponent(String(documentType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication UserRole required
            await setApiKeyToObject(localVarHeaderParameter, "X-CGN-USER-ROLE", configuration)


            if (document !== undefined) { 
                localVarFormParams.append('document', document as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DocumentApi - functional programming interface
 * @export
 */
export const DocumentApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DocumentApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Delete a document
         * @param {string} agreementId Agreement id
         * @param {string} documentType Document type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteDocument(agreementId: string, documentType: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteDocument(agreementId, documentType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get documents
         * @param {string} agreementId Agreement id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDocuments(agreementId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Documents>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDocuments(agreementId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Upload a document
         * @param {string} agreementId Agreement id
         * @param {string} documentType Document type
         * @param {any} document 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadDocument(agreementId: string, documentType: string, document: any, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Document>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadDocument(agreementId, documentType, document, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DocumentApi - factory interface
 * @export
 */
export const DocumentApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DocumentApiFp(configuration)
    return {
        /**
         * 
         * @summary Delete a document
         * @param {string} agreementId Agreement id
         * @param {string} documentType Document type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDocument(agreementId: string, documentType: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteDocument(agreementId, documentType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get documents
         * @param {string} agreementId Agreement id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocuments(agreementId: string, options?: any): AxiosPromise<Documents> {
            return localVarFp.getDocuments(agreementId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Upload a document
         * @param {string} agreementId Agreement id
         * @param {string} documentType Document type
         * @param {any} document 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadDocument(agreementId: string, documentType: string, document: any, options?: any): AxiosPromise<Document> {
            return localVarFp.uploadDocument(agreementId, documentType, document, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DocumentApi - object-oriented interface
 * @export
 * @class DocumentApi
 * @extends {BaseAPI}
 */
export class DocumentApi extends BaseAPI {
    /**
     * 
     * @summary Delete a document
     * @param {string} agreementId Agreement id
     * @param {string} documentType Document type
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentApi
     */
    public deleteDocument(agreementId: string, documentType: string, options?: any) {
        return DocumentApiFp(this.configuration).deleteDocument(agreementId, documentType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get documents
     * @param {string} agreementId Agreement id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentApi
     */
    public getDocuments(agreementId: string, options?: any) {
        return DocumentApiFp(this.configuration).getDocuments(agreementId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Upload a document
     * @param {string} agreementId Agreement id
     * @param {string} documentType Document type
     * @param {any} document 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentApi
     */
    public uploadDocument(agreementId: string, documentType: string, document: any, options?: any) {
        return DocumentApiFp(this.configuration).uploadDocument(agreementId, documentType, document, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DocumentTemplateApi - axios parameter creator
 * @export
 */
export const DocumentTemplateApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Download a document template
         * @param {string} agreementId Agreement id
         * @param {string} documentType Document type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadDocumentTemplate: async (agreementId: string, documentType: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'agreementId' is not null or undefined
            assertParamExists('downloadDocumentTemplate', 'agreementId', agreementId)
            // verify required parameter 'documentType' is not null or undefined
            assertParamExists('downloadDocumentTemplate', 'documentType', documentType)
            const localVarPath = `/agreements/{agreementId}/document-templates/{documentType}`
                .replace(`{${"agreementId"}}`, encodeURIComponent(String(agreementId)))
                .replace(`{${"documentType"}}`, encodeURIComponent(String(documentType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication UserRole required
            await setApiKeyToObject(localVarHeaderParameter, "X-CGN-USER-ROLE", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DocumentTemplateApi - functional programming interface
 * @export
 */
export const DocumentTemplateApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DocumentTemplateApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Download a document template
         * @param {string} agreementId Agreement id
         * @param {string} documentType Document type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadDocumentTemplate(agreementId: string, documentType: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.downloadDocumentTemplate(agreementId, documentType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DocumentTemplateApi - factory interface
 * @export
 */
export const DocumentTemplateApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DocumentTemplateApiFp(configuration)
    return {
        /**
         * 
         * @summary Download a document template
         * @param {string} agreementId Agreement id
         * @param {string} documentType Document type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadDocumentTemplate(agreementId: string, documentType: string, options?: any): AxiosPromise<any> {
            return localVarFp.downloadDocumentTemplate(agreementId, documentType, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DocumentTemplateApi - object-oriented interface
 * @export
 * @class DocumentTemplateApi
 * @extends {BaseAPI}
 */
export class DocumentTemplateApi extends BaseAPI {
    /**
     * 
     * @summary Download a document template
     * @param {string} agreementId Agreement id
     * @param {string} documentType Document type
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentTemplateApi
     */
    public downloadDocumentTemplate(agreementId: string, documentType: string, options?: any) {
        return DocumentTemplateApiFp(this.configuration).downloadDocumentTemplate(agreementId, documentType, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * GeolocationTokenApi - axios parameter creator
 * @export
 */
export const GeolocationTokenApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get token for geolocation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGeolocationToken: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/geolocation-token`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication UserRole required
            await setApiKeyToObject(localVarHeaderParameter, "X-CGN-USER-ROLE", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GeolocationTokenApi - functional programming interface
 * @export
 */
export const GeolocationTokenApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = GeolocationTokenApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get token for geolocation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getGeolocationToken(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GeolocationToken>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getGeolocationToken(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * GeolocationTokenApi - factory interface
 * @export
 */
export const GeolocationTokenApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = GeolocationTokenApiFp(configuration)
    return {
        /**
         * 
         * @summary Get token for geolocation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGeolocationToken(options?: any): AxiosPromise<GeolocationToken> {
            return localVarFp.getGeolocationToken(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * GeolocationTokenApi - object-oriented interface
 * @export
 * @class GeolocationTokenApi
 * @extends {BaseAPI}
 */
export class GeolocationTokenApi extends BaseAPI {
    /**
     * 
     * @summary Get token for geolocation
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GeolocationTokenApi
     */
    public getGeolocationToken(options?: any) {
        return GeolocationTokenApiFp(this.configuration).getGeolocationToken(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * HelpApi - axios parameter creator
 * @export
 */
export const HelpApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Send an help request
         * @param {string} agreementId Agreement id
         * @param {HelpRequest} helpRequest Help request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendHelpRequest: async (agreementId: string, helpRequest: HelpRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'agreementId' is not null or undefined
            assertParamExists('sendHelpRequest', 'agreementId', agreementId)
            // verify required parameter 'helpRequest' is not null or undefined
            assertParamExists('sendHelpRequest', 'helpRequest', helpRequest)
            const localVarPath = `/agreements/{agreementId}/help`
                .replace(`{${"agreementId"}}`, encodeURIComponent(String(agreementId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication UserRole required
            await setApiKeyToObject(localVarHeaderParameter, "X-CGN-USER-ROLE", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(helpRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * HelpApi - functional programming interface
 * @export
 */
export const HelpApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = HelpApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Send an help request
         * @param {string} agreementId Agreement id
         * @param {HelpRequest} helpRequest Help request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendHelpRequest(agreementId: string, helpRequest: HelpRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sendHelpRequest(agreementId, helpRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * HelpApi - factory interface
 * @export
 */
export const HelpApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = HelpApiFp(configuration)
    return {
        /**
         * 
         * @summary Send an help request
         * @param {string} agreementId Agreement id
         * @param {HelpRequest} helpRequest Help request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendHelpRequest(agreementId: string, helpRequest: HelpRequest, options?: any): AxiosPromise<void> {
            return localVarFp.sendHelpRequest(agreementId, helpRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * HelpApi - object-oriented interface
 * @export
 * @class HelpApi
 * @extends {BaseAPI}
 */
export class HelpApi extends BaseAPI {
    /**
     * 
     * @summary Send an help request
     * @param {string} agreementId Agreement id
     * @param {HelpRequest} helpRequest Help request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HelpApi
     */
    public sendHelpRequest(agreementId: string, helpRequest: HelpRequest, options?: any) {
        return HelpApiFp(this.configuration).sendHelpRequest(agreementId, helpRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ProfileApi - axios parameter creator
 * @export
 */
export const ProfileApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create a profile
         * @param {string} agreementId Agreement id
         * @param {CreateProfile} profile Profile to create
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createProfile: async (agreementId: string, profile: CreateProfile, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'agreementId' is not null or undefined
            assertParamExists('createProfile', 'agreementId', agreementId)
            // verify required parameter 'profile' is not null or undefined
            assertParamExists('createProfile', 'profile', profile)
            const localVarPath = `/agreements/{agreementId}/profile`
                .replace(`{${"agreementId"}}`, encodeURIComponent(String(agreementId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication UserRole required
            await setApiKeyToObject(localVarHeaderParameter, "X-CGN-USER-ROLE", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(profile, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a profile
         * @param {string} agreementId Agreement id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProfile: async (agreementId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'agreementId' is not null or undefined
            assertParamExists('getProfile', 'agreementId', agreementId)
            const localVarPath = `/agreements/{agreementId}/profile`
                .replace(`{${"agreementId"}}`, encodeURIComponent(String(agreementId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication UserRole required
            await setApiKeyToObject(localVarHeaderParameter, "X-CGN-USER-ROLE", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a profile
         * @param {string} agreementId Agreement id
         * @param {UpdateProfile} profile Profile to update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProfile: async (agreementId: string, profile: UpdateProfile, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'agreementId' is not null or undefined
            assertParamExists('updateProfile', 'agreementId', agreementId)
            // verify required parameter 'profile' is not null or undefined
            assertParamExists('updateProfile', 'profile', profile)
            const localVarPath = `/agreements/{agreementId}/profile`
                .replace(`{${"agreementId"}}`, encodeURIComponent(String(agreementId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication UserRole required
            await setApiKeyToObject(localVarHeaderParameter, "X-CGN-USER-ROLE", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(profile, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProfileApi - functional programming interface
 * @export
 */
export const ProfileApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProfileApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create a profile
         * @param {string} agreementId Agreement id
         * @param {CreateProfile} profile Profile to create
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createProfile(agreementId: string, profile: CreateProfile, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Profile>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createProfile(agreementId, profile, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get a profile
         * @param {string} agreementId Agreement id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProfile(agreementId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Profile>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProfile(agreementId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update a profile
         * @param {string} agreementId Agreement id
         * @param {UpdateProfile} profile Profile to update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateProfile(agreementId: string, profile: UpdateProfile, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Profile>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateProfile(agreementId, profile, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ProfileApi - factory interface
 * @export
 */
export const ProfileApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProfileApiFp(configuration)
    return {
        /**
         * 
         * @summary Create a profile
         * @param {string} agreementId Agreement id
         * @param {CreateProfile} profile Profile to create
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createProfile(agreementId: string, profile: CreateProfile, options?: any): AxiosPromise<Profile> {
            return localVarFp.createProfile(agreementId, profile, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a profile
         * @param {string} agreementId Agreement id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProfile(agreementId: string, options?: any): AxiosPromise<Profile> {
            return localVarFp.getProfile(agreementId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a profile
         * @param {string} agreementId Agreement id
         * @param {UpdateProfile} profile Profile to update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProfile(agreementId: string, profile: UpdateProfile, options?: any): AxiosPromise<Profile> {
            return localVarFp.updateProfile(agreementId, profile, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProfileApi - object-oriented interface
 * @export
 * @class ProfileApi
 * @extends {BaseAPI}
 */
export class ProfileApi extends BaseAPI {
    /**
     * 
     * @summary Create a profile
     * @param {string} agreementId Agreement id
     * @param {CreateProfile} profile Profile to create
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfileApi
     */
    public createProfile(agreementId: string, profile: CreateProfile, options?: any) {
        return ProfileApiFp(this.configuration).createProfile(agreementId, profile, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a profile
     * @param {string} agreementId Agreement id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfileApi
     */
    public getProfile(agreementId: string, options?: any) {
        return ProfileApiFp(this.configuration).getProfile(agreementId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a profile
     * @param {string} agreementId Agreement id
     * @param {UpdateProfile} profile Profile to update
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfileApi
     */
    public updateProfile(agreementId: string, profile: UpdateProfile, options?: any) {
        return ProfileApiFp(this.configuration).updateProfile(agreementId, profile, options).then((request) => request(this.axios, this.basePath));
    }
}


